import React from 'react'
import service1 from '../../images/service/img-01.jpg'
import service2 from '../../images/service/img-02.jpg'
import './style.css'

const Service = (props) => {
    return(
        <div className="service-area section-padding">
            <div className="container">
                <div className="service-wrap">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                        <div className="service-item">
                                <div className="service-title">
                                    <span>About Us</span>
                                    <h2>Our Mission</h2>
                                    <p>Unity Villa is passionately committed to providing children and youth, especially those with complex needs, the stability and support required to thrive. Our approach combines nurturing care with skill development to promote healing from trauma and neglect.</p>
                                    <h2>Our Vision</h2>
                                    <p>A world where every child, particularly those affected by trauma and neglect, has the opportunity to excel and find a loving home at Unity Villa.</p>
                                    <h2>Our Values</h2>
                                    <p>We believe in reducing trauma through support, healing, and early intervention. Living our Christian values, we maintain integrity and excellence, focusing on each child's best interests to foster healthy, independent adults.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="service-img">
                                <img src={service1} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="service-wrap-s2">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="service-img">
                                <img src={service2} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                        <div className="service-item">
                                <div className="service-title">
                                    <span>How to Help</span>
                                    <h2>Each of these pathways offers a unique way to contribute.</h2>
                                    <p>To make a meaningful impact in the lives of children and youth facing challenges, our organization offers several avenues for involvement.</p>
                                </div>
                                <div className="routine-table">
                                    <ul>
                                        <li>Simple and secure donation options to support our life-changing programs.</li>
                                    </ul>
                                    <ul>
                                        <li>Details on volunteer roles available for those passionate about making a difference in the lives of children and youth.</li>
                                    </ul>
                                    <ul>
                                        <li>Guidance on advocating for the rights and needs of children and youth facing trauma and neglect.</li>
                                    </ul>
                                </div>
                                <div className="btns">
                                    <a href="/programs" className="theme-btn-s3">Know More</a>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Service;