import React from 'react'
import icon1 from '../../images/course/icon1.png'
import icon2 from '../../images/course/icon2.png'
import icon3 from '../../images/course/icon3.png'
import icon4 from '../../images/course/icon4.png'
import icon5 from '../../images/course/icon5.png'
import icon6 from '../../images/course/icon6.png'

import './style.css'

const Course = (props) => {

    const courses = [
        {
          cIcon:icon1,
          heading:"Specialized Living Program",
          pra:"Tailored to support children and youth with complex needs in skill development within a stable, nurturing environment.",
        },
        {
          cIcon:icon2,
          heading:"Cultural Identity Strengthening",
          pra:"A focus on enhancing the cultural identity of indigenous youth while addressing their behavioral, emotional, developmental, social, and physical needs.",
        },
        {
          cIcon:icon3,
          heading:"Inclusive and Individualized Programs",
          pra:"Creation of inclusive, tailored programs to augment community resources, specifically designed to support youth in need.",
        },
        {
          cIcon:icon4,
          heading:"Personal Growth and Independence",
          pra:"Dedicated to assisting the personal growth of complex-needs youth in their journey towards independence.",
        },
        {
          cIcon:icon5,
          heading:"Culturally Specific Multimodal Support",
          pra:"Offering culturally specific alternatives for youth in care, ensuring they remain connected to and supported by their community.",
        },
    ]
    return(
        <div className="courses-area">
            <div className="container">
                <div className="row">
                    {courses.map((cource, i) => (
                        <div className="col-lg-4 col-md-6 custom-grid col-12" key={i}>
                            <div className="courses-item">
                                <div className="course-icon">
                                    <span><img src={cource.cIcon} alt="" /></span>
                                </div>
                                <div className="course-text">
                                    <h2>{cource.heading}</h2>
                                    <p>{cource.pra}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Course;