import React from 'react'
import {Link}  from 'react-router-dom'
import './style.css'

const HeaderTopbar = () => {
    return(	
        <div className="topbar">
            <div className="container">
                <div className="row">
                    <div className="col col-md-6 col-sm-12 col-12">
                        <div className="contact-intro">
                            <ul>
                                <li><i className="fi flaticon-call"></i>+1 403 402 4414</li>
                                <li><i className="fi flaticon-envelope"></i> <a href='mailto:info@unityvilla.ca'>info@unityvilla.ca</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col col-md-6 col-sm-12 col-12" hidden>
                        <div className="contact-info">
                            <ul>
                                <li><Link to="#">&nbsp;</Link></li>
                                <li><Link to="#">&nbsp;</Link></li>
                                <li><Link className="theme-btn" to="/donate">Donate Now</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderTopbar;