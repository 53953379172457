import React from 'react'
import {Link} from 'react-router-dom'
import './style.css'
import { FontAwesomeIcon } from 'react-fontawesome'

const Hero = (props) => {

    return(
        <section  className={`hero ${props.HeroStyleClass}`}>
            <div className="hero-slider">
                <div className="slide">
                    <div className="container">
                        <div className="row">
                            <div className="col col-lg-7 slide-caption">
                                <div className="slide-top">
                                    <span>Unity Villa welcomes you to a place of healing and hope</span>
                                </div>
                                <div className="slide-title">
                                    <h2>where every child is nurtured to overcome challenges and embrace their future with confidence.</h2>
                                </div>
                                <div className="slide-subtitle">
                                    <p>Join our mission to transform lives, one child at a time.</p>
                                </div>
                                <div className="btns">
                                    <Link to="/about-us" className="theme-btn">Discover More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="right-vec">
                        <img class="hero-img" src={props.heroImg} alt=""/>
                        <div className="right-border">
                        <div className="right-icon"><i className="fa fa-2x fa-heart-o"></i></div>
                            <div className="right-icon"><i className="fa fa-2x fa-bed"></i></div>
                            <div className="right-icon"><i className="fi flaticon-wall-clock"></i></div>
                            <div className="right-icon"><i className="fa fa-2x fa-lightbulb-o"></i></div>
                            <div className="right-icon"><i className="fa fa-2x fa-graduation-cap"></i></div>
                            <div className="right-icon"><i className="fa fa-2x fa-sign-language"></i></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Hero;